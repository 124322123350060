<template>
  <div class="box-item" @click="clickItem()">
    <div class="video">
      <img src="../../assets/images/doc.png" alt="" />
    </div>
    <div class="bot">
      <div class="p">
        {{ item.docTitle }}
      </div>
      <div class="ft">
        <div class="user">{{ item.userNickName }}</div>
        <div class="time">
          {{
            `${item.createTime[0]}-${item.createTime[1]}-${item.createTime[2]}`
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from '../../store';
export default {
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      store: Store.state,
      i: {
        img: 'https://polyjetclub-backend.polysmartchain.com/images/normal/866.png',
      },
    };
  },
  methods: {
    clickItem() {
      console.log('1');
      if (this.item.type == 1) {
        console.log('2');
        window.open(this.item.targetUrl, '_blank');
      } else {
        this.store.actDocId = this.item.id;
        window.sessionStorage.setItem('actDocId', this.item.id);
        this.$router.push('/mWorddet');
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box-item {
  /* 矩形 6 */

  /* 矩形 14 */

  width: (174 / 3.75vw);
  height: (168 / 3.75vw);
  border-radius: (6 / 3.75vw);
  margin-top: (10 / 3.75vw);
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 (2 / 3.75vw) (3 / 3.75vw) 0 rgba(176, 176, 176, 0.5);
  .video {
    width: (162 / 3.75vw);
    height: (105 / 3.75vw);
    border-radius: (6 / 3.75vw) (6 / 3.75vw) 0 0;
    background: #ffffff;
    position: relative;
    img {
      /* O1CN01gmmBhj1Cv4l4agZFt_!!0-item_pic */

      /* 路径 */

      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: (73.94 / 3.75vw);
      height: (93 / 3.75vw);
      margin: (6 / 3.75vw) 0;
    }
  }
  .bot {
    .p {
      /* 稳定币发展历程稳定币发展历程稳定币发展历程稳定币发展历程 */
      font-family: SourceHanSansCN-Regular;
      font-size: (14 / 3.75vw);
      height: (30 / 3.75vw);
      overflow: hidden;
      font-weight: normal;
      letter-spacing: 0em;
      padding: (7 / 3.75vw) (9 / 3.75vw) 0;
      color: #333333;
    }
    .ft {
      padding: (0 / 3.75vw) (9 / 3.75vw);
      justify-content: space-between;
      display: flex;
      height: (17 / 3.75vw);
      font-family: SourceHanSansCN-Regular;
      font-size: (12 / 3.75vw);
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
    }
  }
}
</style>
