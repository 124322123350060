<template>
  <div class="wl-home">
    <box-3></box-3>
  </div>
</template>

<script>
import Store from '../../store';
import Box3 from '../home/box3.vue';
export default {
  components: {
    Box3,
  },
  mounted() {},

  data() {
    return {
      uti: Store.uti,
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.wl-home {
  // height: 600px;
  // margin-top: 200px;
  // padding-bottom: 50px;
  width: 100vw;
}
</style>
